
export class AuthenticationDialog{
    callbackFunction : any;
    callbackData : any;
    callFrom : any;
    moduleName : string;
    accessLevel : string;

    constructor(callbackFunction:any,callbackData:any,callFrom : any,moduleName:string,accessLevel:string){
        this.callbackFunction = callbackFunction;
        this.callbackData = callbackData;
        this.callFrom = callFrom;
        this.moduleName = moduleName;
        this.accessLevel = accessLevel;
    }
}