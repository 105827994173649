import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if (localStorage.getItem('kevUser')) {
        //     return true;
        // }
       // console.log(route);
       // console.log(state);
        let urlarr = state.url.split('/');
        let currentuser = this.authService.userDetails;
        if (this.authService.userDetails != null) {
            if(state.url.toLowerCase().includes('dashboard') 
            || (state.url.toLowerCase().includes('settings') &&  this.authService.IsAdminOrSuperAdmn) || state.url == "/" || state.url.toLowerCase().includes('worker')){
                if(state.url.toLowerCase().includes('settings') && state.url.toLowerCase().includes('license') &&  !this.authService.IsSuperAdmin)
                {
                    return false;
                }
                else
                {
                   return true;
                }
            }
            
            for (let privilege of currentuser.privileges) {
               // let privilegearr = privilege.split(' ');
                if (privilege.ModuleName.replace(/\s/g, "").toLowerCase().includes(urlarr[1]) || privilege.ModuleName.replace(/\s/g, "").toLowerCase().includes(urlarr[2])) {
                    if (state.url.toLowerCase().includes('edit')) {
                        return privilege.Update;
                    }
                    else if (state.url.toLowerCase().includes('new')||state.url.toLowerCase().includes('clone')) {
                        return privilege.Write;
                    }
                    // else {
                    //     return true;
                    // }
                    
                    return privilege.Read;
                }
            }
        }
        else
        {
            this.router.navigate(['/login']);
            return false;
        }
        // this.router.navigate(['/login']);
        // return false;
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
      }
}
