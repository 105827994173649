
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable, } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './auth.service';
import { AppConfig } from '../../app.config';
import { json } from 'd3';


@Injectable()
export class SharedService {
    canViewCompany: boolean = false;
    canWriteCompany: boolean = false;
    canUpdateCompany: boolean = false;

    canViewPlant: boolean = false;
    canWritePlant: boolean = false;
    canUpdatePlant: boolean = false;

    canViewPlantLine: boolean = false;
    canWritePlantLine: boolean = false;
    canUpdatePlantLine: boolean = false;

    canViewProduct: boolean = false;
    canWriteProduct: boolean = false;
    canUpdateProduct: boolean = false;

    canViewBatch: boolean = false;
    canWriteBatch: boolean = false;
    canUpdateBatch: boolean = false;

    canViewUser: boolean = false;
    canWriteUser: boolean = false;
    canUpdateUser: boolean = false;

    canViewEquipment: boolean = false;
    canWriteEquipment: boolean = false;
    canUpdateEquipment: boolean = false;

    canViewReports: boolean = false;
    canViewProductionReport: boolean = false;
    canViewAuditReport: boolean = false;
    canViewBulkImport: boolean = false;
    userDetails: any;
    licenseDetails: any;

    domain: any = AppConfig.settings.endpoint;

    private commonUrl : string = "api/common/";

    constructor(private http: HttpClient,private auth: AuthenticationService) {
        this.assignViewAsPerRole();
        this.checkLicense();
    }

    assignViewAsPerRole() {
        this.auth.getAuthUserDetails();
        this.userDetails = this.auth.userDetails;
        let currentUserDetails = JSON.parse(sessionStorage.getItem('kevUserDetail'));
        if (currentUserDetails != null) {
            this.userDetails.pic= AppConfig.settings.endpoint + currentUserDetails.profilepic;
        }
        for (let privilege of this.userDetails.privileges) {
            switch (privilege.ModuleName) {
                case "Company":
                        this.canViewCompany = privilege.Read;
                        if(this.auth.IsSuperAdmin){
                        this.canWriteCompany = privilege.Write;
                        this.canUpdateCompany = privilege.Update;
                        }
                    break;

                case "Plants":
                    this.canViewPlant = privilege.Read;
                    this.canWritePlant = privilege.Write;
                    this.canUpdatePlant = privilege.Update;
                    break;

                case "PlantLines":
                    this.canViewPlantLine = privilege.Read;
                    this.canWritePlantLine = privilege.Write;
                    this.canUpdatePlantLine = privilege.Update;
                    break;

                case "Products":
                    this.canViewProduct = privilege.Read;
                    this.canWriteProduct = privilege.Write;
                    this.canUpdateProduct = privilege.Update;
                    break;

                case "Batches":
                    this.canViewBatch = privilege.Read;
                    this.canWriteBatch = privilege.Write;
                    this.canUpdateBatch = privilege.Update;
                    break;

                case "Users":
                    this.canViewUser = privilege.Read;
                    this.canWriteUser = privilege.Write;
                    this.canUpdateUser = privilege.Update;
                    break;

                case "Reports":
                    this.canViewReports = privilege.Read;
                    break;

                case "Equipments":
                    this.canViewEquipment = privilege.Read;
                    this.canWriteEquipment = privilege.Write;
                    this.canUpdateEquipment = privilege.Update;
                        break;
                case "BulkImport":
                    this.canViewBulkImport = privilege.Read;
                    break;
                default:
                    this.auth.logout(false);
                    break;
            }
        }
    }

    getCountry()
    {
        let authHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.get(AppConfig.settings.endpoint  + this.commonUrl + 'getcountrylist', { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

    getState(countryname)
    {
        let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.post(AppConfig.settings.endpoint  + this.commonUrl + 'getstatelist?country=' + countryname , { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

    getCity(countryname,statename)
    {
        let authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.post(AppConfig.settings.endpoint  + this.commonUrl + 'getcitylist',{country : countryname,state : statename} , { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

//#region Dashboard
    getDashboardData()
    {
        let authHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.get(AppConfig.settings.endpoint  + this.commonUrl + 'dashboardinfo', { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

    getDateWiseACSDataByBatch(batchName){
        let authHeader = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.post(AppConfig.settings.endpoint  + this.commonUrl + "getdatewiseacsdatabybatch", {batchName:batchName},{ headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

    GetACSDataByLastBatches(batchCount){
        let authHeader = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.post(AppConfig.settings.endpoint  + this.commonUrl + "getacsdatabylastbatches", JSON.stringify({batchCount:batchCount}),{ headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }
//#endregion


    uploadXMLFile(file:File)
    {
        var formdata = new FormData();
        formdata.append('file', file);
        let customHeader = new HttpHeaders({'Content-Type':'multipart/form-data','Access-Control-Allow-Origin':'*'});
        return this.http.post("http://localhost:56961/api/tracelink",formdata,{headers:customHeader}).pipe(catchError(e => this.handleError(e)));;
    }

    getRegulationCountries() {
          const authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
          return this.http.get(AppConfig.settings.endpoint   + 'api/common/regulationcountries',
          { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

    getRegulationList() {
       const authHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
      return this.http.get(AppConfig.settings.endpoint + 'api/common/regulationlist',
         { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

    getCurrency()
    {
        let authHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.get(AppConfig.settings.endpoint  + this.commonUrl + 'getcurrencylist', { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

    getLanguage()
    {
        let authHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.get(AppConfig.settings.endpoint  + this.commonUrl + 'getlanguagelist', { headers: authHeader }).pipe(catchError(e => this.handleError(e)));
    }

    getRegulationRule(regulation)
    {
        let authHeader = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.auth.getAuthToken() });
        return this.http.post(AppConfig.settings.endpoint  + this.commonUrl + "getRegulationRule?regulationName=" + regulation ,{ headers: authHeader }).pipe(catchError(e => this.handleError(e)));
     }

    checkLicense() {
        this.licenseDetails = JSON.parse(sessionStorage.getItem('licenseDetails'));
    }

    private handleError(error: any): Observable<any> {
        let errresponse : Observable<any>;
        switch(error.status)
        {
            case 400:
                if(error.hasOwnProperty("ModelState"))
                {
                    
                    let errors = [];
                    let validationErrorDictionary = JSON.parse(JSON.stringify(error.ModelState));
                    for (var fieldName in validationErrorDictionary) {
                        if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                            errors.push(validationErrorDictionary[fieldName]);
                        }
                    }
                    errresponse = observableThrowError(errors);
                }
                else
                {
                    errresponse = observableThrowError(error.error.Message);
                }
            break;

            case 401:
                errresponse = observableThrowError("<b>Authorization Failed </b>" + error.statusText);
            break;

            case 403:
                 errresponse = observableThrowError("<b>Forbidden </b>" + error.statusText);
            break;

            case 500:
                errresponse = observableThrowError("<b>Server Error </b>" + error.statusText);
            break;

            default:
                errresponse = observableThrowError("<b>Unable to connect to the server </b>" + error.statusText);
            break;
        }
        return errresponse;
    }

}
