<div class="sidebar-content">
  <!-- Main Navigation -->
  <div class="sidebar-user">
    <div class="card-body">
      <div class="media">
        <div class="mr-3">
          <a href="#" [routerLink]="['/users/edit',UserId]"><img [src]="sharedService.userDetails.pic != null ? sharedService.userDetails.pic : 'assets/kev/images/nia.jpg' " width="38" height="38" class="rounded-circle" alt=""></a>
        </div>

        <div class="media-body">
          <div class="media-title font-weight-semibold">{{sharedService.userDetails.username}}</div>
          <div class="font-size-xs opacity-50">
            <i class="icon-envelop font-size-sm"></i> {{sharedService.userDetails.emailid}}
          </div>
        </div>
      </div>
      
    </div>
  </div>
  <div class="card card-sidebar-mobile">
      <ul class="nav nav-sidebar" data-nav-type="accordion">
        <li  class="nav-item">
          <a [routerLink]="['/dashboard']" title="Dashboard" [routerLinkActive]="['active']" class="nav-link"><i class="icon-home4"></i><span>Dashboard</span></a>
          <!-- <a href="/dashboard"><i class="fa fa-home"></i> <span>Dashboard</span></a> -->
        </li>
        <li  class="nav-item"  *ngIf="sharedService.canViewProduct">
          <a [routerLink]="['/products']" class="nav-link" [routerLinkActive]="['active']" title="Manage Products"><i class="icon-stack2"></i><span>Products</span></a>
          <!-- <a href="/products"><i class="fa fa-briefcase"></i> <span>Product</span></a> -->
        </li>
        <li class="nav-item"  *ngIf="sharedService.canViewBatch">
          <a [routerLink]="['/batches']" class="nav-link" [routerLinkActive]="['active']" title="Manage Batches"><i class="icon-stack"></i><span>Batches</span></a>
          <!-- <a href="/dashboard"><i class="fa fa-home"></i> <span>Dashboard</span></a> -->
        </li>
        <li class="nav-item" *ngIf="sharedService.canViewUser">
          <!-- <a href="#"><i class="icon-briefcase"></i> <span></span></a> -->
          <a [routerLink]="['/users']"  class="nav-link" [routerLinkActive]="['active']" title="Manage Users"><i class="icon-user"></i><span>Users</span></a>
         
        </li>


        <li class="nav-item nav-item-submenu" *ngIf="sharedService.canViewCompany || sharedService.canViewPlant || sharedService.canViewPlantLine">              
          <a href="#" title="Components Hierarchy" class="nav-link has-ul"><i><span class="icon-tree6"></span></i><span>Hierarchy</span></a>             
          <ul id="setup-child-list" class="nav nav-group-sub" data-submenu-title="Layouts">
            <li class="nav-item" *ngIf="sharedService.canViewCompany">
              <a [routerLink]="['/hierarchy/company']" [routerLinkActive]="['active']" class="nav-link" title="Manage Company"><i class="icon-library2"></i><span>{{authService.isSuperAdmin?"Companies":"Company"}}</span></a>
              <!-- <a href="/dashboard"><i class="fa fa-home"></i> <span>Dashboard</span></a> -->
            </li>
            <li class="nav-item" *ngIf="sharedService.canViewPlant">
              <a [routerLink]="['/hierarchy/plants']" [routerLinkActive]="['active']" class="nav-link" title="Manage Plants"><i class="icon-tree5"></i><span>Plants</span></a>
              <!-- <a href="/dashboard"><i class="fa fa-home"></i> <span>Dashboard</span></a> -->
            </li>
            <li class="nav-item" *ngIf="sharedService.canViewPlantLine">
              <a [routerLink]="['/hierarchy/equipments']" [routerLinkActive]="['active']" class="nav-link" title="Manage Equipments"><i class="icon-box"></i><span>Equipment</span></a>
              <!-- <a href="/dashboard"><i class="fa fa-home"></i> <span>Dashboard</span></a> -->
            </li> 
            <li class="nav-item" *ngIf="sharedService.canViewPlantLine">
              <a [routerLink]="['/hierarchy/plantlines']" [routerLinkActive]="['active']" class="nav-link" title="Manage Plantlines"><i class="icon-tree7"></i><span>Plantlines</span></a>
              <!-- <a href="/dashboard"><i class="fa fa-home"></i> <span>Dashboard</span></a> -->
            </li>
          </ul>
        </li>



        <li class="nav-item nav-item-submenu"  *ngIf="sharedService.canViewReports">
          <a href="#" title="System Reports" class="nav-link has-ul"><i class="icon-graph"></i> <span>Reports</span></a>
          <ul class="nav nav-group-sub" data-submenu-title="Layouts">
            <li class="nav-item nav-item-submenu" >
              <a href="#" title="Production " class="nav-link has-ul" ><i class="icon-grid5"></i><span>Production</span></a>
              <ul class="nav nav-group-sub" data-submenu-title="Layouts">
                <li class="nav-item">
                  <a [routerLink]="['/report/production/productionreport']" [routerLinkActive]="['active']" class="nav-link" title="Production Report" ><i class="icon-grid"></i><span>Production Report</span></a>
                </li>
                <li class="nav-item">
                  <a [routerLink]="['/report/production/BufferReport']" [routerLinkActive]="['active']" class="nav-link" title="Buffer Report"><i class="icon-grid"></i><span>Buffer Report</span></a>
                </li>   
                <li class="nav-item">
                  <a [routerLink]="['/report/production/GradeReport']" [routerLinkActive]="['active']" class="nav-link" title="Grade Report"><i class="icon-grid"></i><span>Grade Report</span></a>
                </li>
                <li class="nav-item">
                  <a [routerLink]="['/report/production/parentchildreport']" [routerLinkActive]="['active']" class="nav-link" title="Parent-Child Relationship Report"><i class="icon-grid"></i><span>PCR Report</span></a>
                </li> 
              </ul>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/report/audit']" [routerLinkActive]="['active']" class="nav-link" title="User Audit Trail Report"><i class="fa fa-file"></i><span>User Audit Report</span></a>
              <!-- <i><span class="glyphicon glyphicon-duplicate"></span></i> -->
            </li>
            <li class="nav-item">
              <a [routerLink]="['/report/user']" [routerLinkActive]="['active']" class="nav-link" title="User Report"><i class="icon-user"></i><span>User Report</span></a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/report/batch-audit']" [routerLinkActive]="['active']" class="nav-link" title="Batch Audit Trail Report"><i class="icon-stack"></i><span>Batch Audit Report</span></a>
            </li>
            <!--<li class="nav-item">
              <a [routerLink]="['/report/chart']" [routerLinkActive]="['active']" class="nav-link" title="Serial Number Details Chart Report"><i class="icon-chart"></i><span>Serial Number Chart</span></a>
            </li>-->
            <li class="nav-item">
              <a [routerLink]="['/worker']" [routerLinkActive]="['active']" class="nav-link" title="Background Job Status"><i class="icon-chart"></i><span>Background Jobs</span></a>
            </li>
          </ul>
        </li>     
        
        <li  class="nav-item nav-item-submenu" *ngIf="authService.IsAdminOrSuperAdmn">              
          <a href="#" title="Master Settings" class="nav-link has-ul"><i><span class="icon-cog3"></span></i><span>Settings</span></a>             
          <ul id="settings-child-list" class="nav nav-group-sub" data-submenu-title="Layouts">
            <li class="nav-item">
              <a [routerLink]="['/settings/accountsettings']" [routerLinkActive]="['active']" class="nav-link" title="Account Settings"><i class="icon-vcard"></i><span>Account</span></a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/batchsettings']" [routerLinkActive]="['active']" class="nav-link" title="Batch Status Settings"><i class="icon-stack"></i><span>Batch</span></a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/codesettings']" [routerLinkActive]="['active']" class="nav-link" title="Serial Number Status Settings"><i class="icon-grid5"></i><span>Serial Number Status</span></a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/packagesettings']" [routerLinkActive]="['active']" class="nav-link" title="Packaging Settings"><i class="icon-stack2"></i><span>Packaging Level</span></a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/tpintegration']" [routerLinkActive]="['active']" class="nav-link" title="Third Party Integration Settings"><i class="icon-hyperlink"></i><span>TP Integrations</span></a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/customersettings']" [routerLinkActive]="['active']" class="nav-link" title="Customer Settings"><i class="icon-cog"></i><span>Customer Settings</span></a>
            </li>
            <li class="nav-item" *ngIf="authService.IsSuperAdmin">
              <a [routerLink]="['/settings/systemsettings']" [routerLinkActive]="['active']" class="nav-link" title="System Settings"><i class="icon-cog"></i><span>System Settings</span></a>
            </li>
            <li *ngIf="authService.IsSuperAdmin" class="nav-item">
              <a [routerLink]="['/settings/license']" [routerLinkActive]="['active']" class="nav-link" title="License Manager"><i class="icon-libreoffice"></i><span>License</span></a>
            </li>
          </ul>
        </li>

        <li class="nav-item" *ngIf="sharedService.canViewBulkImport" >
          <!-- <a href="#"><i class="icon-briefcase"></i> <span></span></a> -->
          <a [routerLink]="['/bulkimport']" [routerLinkActive]="['active']" class="nav-link" title="Bulk Data Import"><i class="icon-file-pdf"></i><span>Bulk Data Import</span></a>
         
        </li>
<!-- 
        <li *ngIf="!sharedService.domain.production">
          <a [routerLink]="['/test']" title="Test Page"><i><span class="glyphicon glyphicon-cog"></span></i><span>Test Page</span></a>             
        </li> -->
      </ul>
  </div>
</div>
