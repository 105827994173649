import { Component, Input,Output,EventEmitter,forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboBoxComponent),
    multi: true
};

@Component({
    selector: 'customcombobox',
    templateUrl: './combobox.component.html',
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
  //  styleUrls: ['./sidebar.component.scss']
})



export class ComboBoxComponent implements ControlValueAccessor {

    //The internal data model
    private innerValue: any = '';

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    @Input() ComboBoxOptionalValue : string = "";
    @Input() DisableComboBox: boolean = false;
    @Input() ComboBoxId : string = "";
    @Input() ComboBoxOptionalName : string = "";
    @Input() ComboBoxName:string ="";
    @Input() ComboBoxSelectedValue: string = "";
    @Input() dataArray : any [] = [] ;

    @Output() ComboBoxChange : EventEmitter<any> = new EventEmitter();
    constructor(){}

    ComboBoxChanged()
    {
        this.ComboBoxChange.emit(this);
    }

     //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}