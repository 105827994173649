import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDialogBox } from '../models/common/ConfirmDialog.model';
import { AuthenticationDialog } from '../models/common/AuthenticationDialog.model';

@Injectable()
export class LoaderService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public confirmDialog: BehaviorSubject<ConfirmDialogBox> = new BehaviorSubject<ConfirmDialogBox>(null);
    public hideModalOnLogout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public authDialogBox: BehaviorSubject<AuthenticationDialog> = new BehaviorSubject<AuthenticationDialog>(null);

    display(value: boolean) {
        this.status.next(value);
       if(document.body.style.paddingRight != "")
       {
           document.body.style.paddingRight = null;
       }
    }

    confirmDialogShow(data:ConfirmDialogBox)
    {
        this.confirmDialog.next(data);
    }

    //functionName : will call if authentication verified
    //funData : function's parameters, pass it as an array form.
    //callForm : reference(this) of from where 'AuthenticateFirst' called.
    //moduleName : Module name like 'Product Module' etc.
    //accessLevel : Access Level of modules. 'Write','Update' and 'Read' these are levels.
    AuthenticateFirst(functionName:any,funData:Array<any>,callFrom,moduleName,accessLevel){              
        var authDialogData : AuthenticationDialog = new AuthenticationDialog(functionName,funData,callFrom,moduleName,accessLevel);       
        this.authDialogShow(authDialogData);
    }    

    authDialogShow(data : AuthenticationDialog)
    {
        this.authDialogBox.next(data);
    }

}