
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';


import { AppConfig } from '../../app.config';

@Injectable()
export class AuthenticationService {

    public token: string;

    private username: string;

    public userDetails: any;

    private roles: any;

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    public configuration: string;

    private isSuperAdmin : boolean;
    public get IsSuperAdmin() : boolean {
        return this.isSuperAdmin;
    }
    private isAdmin : boolean;
    public get IsAdmin() : boolean {
        return this.isAdmin;
    }
    public get IsAdminOrSuperAdmn() : boolean {
        return this.isAdmin || this.isSuperAdmin;
    }

    constructor(private http: HttpClient, private router: Router) {
        // set token if saved in local storage
        this.getAuthUserDetails();
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post(AppConfig.settings.endpoint + 'api/token/signin', JSON.stringify({ username: username, password: password }), { headers: this.headers }).pipe(
            map((response: HttpResponse<any>) => {
                // login successful if there's a jwt token in the response
                
                let userName = response["UserName"];
                let token = response && response["token"];
                let userDetail = response["user"];
                let privilegedetail = response["privileges"];
                let licenseDetails = response["licenseDetails"]
                if (token != "" && userDetail.IsFirstTimeLogin == undefined) {
                    // set token property

                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('kevUser', JSON.stringify({ username: userName, token: token }));
                    sessionStorage.setItem('kevUserDetail', JSON.stringify({ id: userDetail._id,
                         fullname: userDetail.FirstName + ' ' + userDetail.LastName, emailid: userDetail.EmailId, profilepic: userDetail.UserImage,statusMessage:userDetail.statusMessage, privileges: privilegedetail.ModuleAccessList, companyId : privilegedetail.CompanyId }))
                    sessionStorage.setItem("tokenExpireTime",JSON.stringify({tokenExpireTime : ( response["tokenExpireMinutes"] * 60 * 1000 )}));
                    //debugger;
                    if(licenseDetails != null) {
                        sessionStorage.setItem("licenseDetails", JSON.stringify(licenseDetails));
                    }
                    this.getAuthUserDetails(); 
                    return "1";
                }
                else if (token != "" && userDetail.statusCode == "PasswordExpired") {
                    sessionStorage.setItem('kevUser', JSON.stringify({ username: username, token: token }));
                    sessionStorage.setItem('kevUserDetail', JSON.stringify({companyId : userDetail.CompanyId}));
                    return "3";
                }
                else if (token != "" && userDetail.IsFirstTimeLogin === 1) {
                    sessionStorage.setItem('kevUser', JSON.stringify({ username: username, token: token }));
                    sessionStorage.setItem('kevUserDetail', JSON.stringify({companyId : userDetail.CompanyId}));
                    return "2";
                }
                else {
                    // return false to indicate failed login
                    return userDetail;
                }
            }),catchError(e => {
                if (e.status === 401) {
                    return observableThrowError('UserName/Password Invalid!!!');
                }
                else if (e.status === 400)
                {
                    return observableThrowError(e.error.Message);
                }
                else {
                    return observableThrowError('Unable to connect to the server!!!');
                    // alert('Error Occurred' + e);
                }
                // do any other checking for statuses here
            }),);
    }

    logout(Isautologout): void {
        // clear token remove user from local storage to log user out
        if (this.username != null) {
          this.http.post(AppConfig.settings.endpoint + 'api/token/signout?username=' + this.username + "&Isautologout=" + Isautologout, { headers: this.headers })
                .subscribe(response => {
                    this.token = null;
                    this.username = null;
                    sessionStorage.removeItem('kevUser');
                    sessionStorage.removeItem('kevUserDetail');
                    sessionStorage.removeItem('tokenExpireTime');
                    sessionStorage.removeItem('licenseDetails');
                    setTimeout(() => { this.router.navigateByUrl("login") }, 1500);
                }, (Error) => {
                   // alert(Error);
                });
        }
        else {
            this.token = null;
            this.username = null;
            sessionStorage.removeItem('kevUser');
            sessionStorage.removeItem('kevUserDetail');
            sessionStorage.removeItem('tokenExpireTime');
            sessionStorage.removeItem('licenseDetails');
        }
    }

    getAuthToken(): string {
        let jtoken = JSON.parse(sessionStorage.getItem('kevUser'));
        this.token = jtoken.token;
        return this.token;
    }

    getAuthUserRoles(token) {
        if (token != null) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            var response = JSON.parse(window.atob(base64));
            this.roles = response.role;
            // this.enableViewAsPerRole();
        }
    }

    getAuthUserDetails() {
        let currentUserAccount = JSON.parse(sessionStorage.getItem('kevUser'));
        let currentUserDetails = JSON.parse(sessionStorage.getItem('kevUserDetail'));
        if (currentUserAccount != null && currentUserDetails != null) {
            this.token = currentUserAccount.token;
            this.username = currentUserAccount.username;
            this.getAuthUserRoles(this.token);
            this.userDetails = {
                userid: currentUserDetails.id,
                username: this.username,
                fullname: currentUserDetails.fullname,
                emailid: currentUserDetails.emailid,
                roles: this.roles,
                privileges: currentUserDetails.privileges,
                companyId : currentUserDetails.companyId
            };
        }
        else {
            this.token = null;
            this.username = null;
            this.userDetails = null;
        }
        this.SetAdminSuperAdminValue();
    }

    SetAdminSuperAdminValue(){
        this.isAdmin = false;
        this.isSuperAdmin = false;
        if(this.roles == null)
        {
            return;
        }
        const role: string = this.roles.trim().toLocaleLowerCase();
        if (role === 'administrator') {
            this.isAdmin = true;
        }
        if (role === 'superadmin') {
            this.isSuperAdmin = true;
        }
    }

    validateToken() {
        let authHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + this.getAuthToken() });
        return this.http.get(AppConfig.settings.endpoint + 'api/token/validate', { headers: authHeader }).subscribe(response => {
        }, (Error) => {
            if (Error.status === 401) {
                this.logout(false);
            }
            else {
              //  alert(Error);
            }
        });
    }


    private handleError(error: any): Observable<any> {
        let errresponse: Observable<any>;
        switch (error.status) {
            case 400:
                if(error.hasOwnProperty("ModelState"))
                {
                    
                    let errors = [];
                    let validationErrorDictionary = JSON.parse(JSON.stringify(error.ModelState));
                    for (var fieldName in validationErrorDictionary) {
                        if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                            errors.push(validationErrorDictionary[fieldName]);
                        }
                    }
                    errresponse = observableThrowError(errors);
                }
                else
                {
                    errresponse = observableThrowError(error.error.Message);
                }
                break;

            case 401:
                errresponse = observableThrowError("<b>Authorization Failed " + error.statusText);
                break;

            case 500:
                errresponse = observableThrowError("<b>Server Error </b>" + error.statusText);
                break;

            default:
                errresponse = observableThrowError("<b>Unable to connect to the server </b>" + error.statusText);
                break;
        }
        return errresponse;
    }
}
