import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
// import { AboutComponent } from './about/about.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [AuthGuard]
    },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'feedback', loadChildren: ()=> import('./kevision-feedback/feedback.module').then(m => m.FeedbackModule)},
    //{ path: 'worker', component: BackGroundJobComponent },
    // { path: 'worker', loadChildren: () => import('./export/backgroundjob.module').then(bg => bg.BackGroundJobModule), pathMatch: "full" },
    { path: '**', redirectTo: '/login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,  {
        // Tell the router to use the HashLocationStrategy.
        useHash: true,
        enableTracing: false
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }