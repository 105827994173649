import { Component,OnInit } from '@angular/core';
import { AuthenticationService } from '../services/auth.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html'
})

export class AboutComponent implements OnInit {

    featurePanelClass: string = '';
    isAuthorized : boolean = false;

    constructor(private authService: AuthenticationService, ) {
    }

    ngOnInit(){
        this.initComponent();
    }

    openDoc(docname: string) {
        let serverURL = AppConfig.settings.endpoint ;
        let fileurl: string = '';
        this.checkAuthorization();
        if (this.isAuthorized) {
            switch (docname) {
                case "ts":
                    fileurl = 'kevdocs/technicalspecification.pdf';
                    break;

                case "sop":
                    fileurl = 'kevdocs/sop.pdf';
                    break;

                case "webapi":
                    fileurl = 'kevdocs/webapi.pdf';
                    break;

                case "pr":
                    fileurl = 'kevdocs/productionreport.pdf';
                    break;

                case "ar":
                    fileurl = 'kevdocs/auditreport.pdf';
                    break;

                case "flowchart":
                    fileurl = 'kevdocs/flowchart.pdf';
                    break;

                case "sitemap":
                    fileurl = 'kevdocs/sitemap.pdf';
                    break;

                default:
                    fileurl = '';

            }
            let url = serverURL + fileurl;
            window.open(url);
            
        }
        else
        {
         //   alert('Not Authorized!!!');
        }
    }

    checkAuthorization()
    {
        this.authService.getAuthUserDetails();
        if(this.authService.token === null)
        {
            this.isAuthorized = false;
        }
        else
        {
            this.isAuthorized = true;
        }
    }

    initComponent()
    {

        this.checkAuthorization();
        if(this.isAuthorized)
        {
            this.featurePanelClass = 'col-lg-9';
        }
        else
        {
            this.featurePanelClass = 'col-lg-12'
        }
    }
}