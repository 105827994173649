import { Component } from '@angular/core';
import { AuthenticationService } from '../../../services/auth.service';
import { SharedService } from '../../../services/shared.service';

@Component({
    selector: 'div.sidebar',
    templateUrl: './sidebar.component.html',
  //  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    isActive = false;
    showMenu = '';
    UserId = '';
     
    constructor(
        public authService : AuthenticationService,
        public sharedService : SharedService
    )
    {
        this.sharedService.assignViewAsPerRole();
    }

    ngOnInit(){
        var result = sessionStorage.getItem('kevUserDetail');
        var jresult = JSON.parse(result);
        this.UserId = jresult.id;
    }

    eventCalled() {
        this.isActive = !this.isActive;
    }
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    
}
